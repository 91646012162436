import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import styled from "styled-components";
import Switch from "react-switch";
import randomstring from "randomstring";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Header from "./../../components/Header";
import LISTA from "./../../assets/svg/lista.svg";
import { AppContext } from "./../../AppContext";

import { sendQuestionnaire } from "./../../services";

const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ContainerW = styled(Container)`
  margin-top: 130px;
  overflow: hidden;
  & {
    @media screen and (max-width: 400px) {
      margin-top: 120px;
    }
  }
`;
const Div = styled.div`
  width: 100%;
  margin: 5px 5px 20px 5px;
`;

const Questionnaire = () => {
  let history = useHistory();

  const { questionnaire, language, setDoQuestionnaire } = useContext(
    AppContext
  );

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0); //0 questionnaire //1 success //2 error
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  useEffect(() => {
    main();
  }, []);

  const main = () => {
    if (!language) {
      history.push("/languages");
    }
    const aux = questionnaire.map((item) => {
      item.respuestas.map((el) => (el.value = false));
      return item;
    });
    setQuestions(aux);
  };

  const handleChange = (question, answer) => {
    let _questions = questions;
    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].id === question) {
        let flag = false;
        for (let j = 0; j < _questions[i].respuestas.length; j++) {
          if (_questions[i].respuestas[j].id === answer) {
            if (_questions[i].respuestas[j].value) {
              _questions[i].respuestas[j].value = false;
              flag = true;
            } else {
              _questions[i].respuestas[j].value = true;
              flag = true;
            }
          } else {
            if (_questions[i].multiple === "0" && answer) {
              _questions[i].respuestas[j].value = false;
            }
          }
        }
        if (flag) break;
      }
    }
    setQuestions(_questions);
    setRa(Math.floor(Math.random() * 11));
    prepareAnswer();
  };

  const prepareAnswer = () => {
    const aux = [];
    const questionnaireId = randomstring.generate();
    let complete = true;
    questions.forEach((item) => {
      let flag = false;
      for (let i = 0; i < item.respuestas.length; i++) {
        if (item.respuestas[i].value) {
          flag = true;
          aux.push({
            idcliente: questionnaireId,
            valor: true,
            idioma_id: language.id,
            respuesta_id: item.respuestas[i].id,
            pregunta_id: item.id,
          });
          break;
        }
      }
      if (!flag) complete = false;
    });
    if (!complete) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setAnswers(aux);
  };

  const send = async () => {
    try {
      setLoading(true);
      await sendQuestionnaire(JSON.stringify(answers));
      setLoading(false);
      setView(1);
      reactLocalStorage.set("questionnaireCompleted", true);
      setDoQuestionnaire(false);
    } catch (error) {
      setLoading(false);
      setView(2);
    }
  };

  return (
    <React.Fragment>
      <Header />
      {language && (
        <ContainerW className="">
          <Row
            style={{
              borderBottom: "1px solid #888888",
              paddingBottom: 10,
              marginBottom: 15,
            }}
          >
            <Col>
              <img
                style={{
                  height: 20,
                  margin: "0 10px 0px 10px",
                }}
                src={LISTA}
                alt=""
              />{" "}
              {language.CUESTIONARIO}
            </Col>
          </Row>
          {view === 0 && (
            <React.Fragment>
              <Row>
                <Col>
                  {language &&
                    questions.map((question) => {
                      return (
                        <Div>
                          <Row>
                            <Col className="col-12">
                              <p style={{ margin: "5px 0px 5px 10px" }}>
                                {question.numero}.{" "}
                                {question.traducciones[language.abreviatura]}
                              </p>
                            </Col>
                          </Row>
                          {question.respuestas.map((item) => {
                            return (
                              <Row className="my-1">
                                <Col className="col-12 d-flex justify-content-start">
                                  <Switch
                                    key={`item-${item.id}-${ra}`}
                                    checked={item.value}
                                    onChange={() => {
                                      handleChange(question.id, item.id);
                                    }}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                  />
                                  <p style={{ margin: "5px 0px 0 10px" }}>
                                    {item.traducciones[language.abreviatura]}
                                  </p>
                                </Col>
                              </Row>
                            );
                          })}
                        </Div>
                      );
                    })}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <AppButton
                    onClick={send}
                    disabled={disabled}
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    {loading && (
                      <Spinner style={{ marginTop: 10 }} animation="grow" />
                    )}
                    {!loading && <React.Fragment>ENVIAR</React.Fragment>}
                  </AppButton>
                </Col>
              </Row>
            </React.Fragment>
          )}

          {view === 1 && (
            <Alert
              style={{ marginTop: 50 }}
              key="alertSuccess"
              variant="success"
            >
              El cuestionario ha sido enviado con exito!!!
            </Alert>
          )}
          {view === 2 && (
            <Alert style={{ marginTop: 50 }} key="alertError" variant="danger">
              Lo sentimos, ha ocurrido un error!!!
            </Alert>
          )}
        </ContainerW>
      )}
    </React.Fragment>
  );
};

export default Questionnaire;
