import React, { useState, useContext } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { reactLocalStorage } from "reactjs-localstorage";
import { useHistory } from "react-router-dom";

import Header from "./../../components/Header";
import { AppContext } from "./../../AppContext";

import { getAllData } from "./../../services";

const AppButton = styled(Button)`
  color: #000000;
  background-color: #ffffff;
  font-family: HelveticaNeue-Regular;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 0;
  border: none;
  box-shadow: 8px 8px 5px #dfdedb;
  &:hover {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
  }
  &:focus {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 8px 8px 5px #dfdedb;
  }
  &:disabled {
    color: #000000;
    background-color: #ffffff;
    cursor: default;
    outline: none;
  }
`;

const ContainerW = styled(Container)`
  margin-top: 130px;
  & {
    @media screen and (max-width: 400px) {
      margin-top: 100px;
    }
  }
`;

const CodeNumber = styled.input`
  color: #000000;
  width: 40px;
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  height: 55px;
  font-size: 50px;
  text-align: center;
  padding: 0;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
  font-family: Arial; //or Helvetica, or whatever
  /* Firefox */
  &:input[type="number"] {
    -moz-appearance: textfield;
  }
  &:focus {
    outline: none;
  }
  line-height: 0;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: blue;
  }
`;

const Div = styled.div``;
const RowW = styled(Row)`
  margin: 0;
  padding: 0 20px;
  margin-top: 130px;
`;
const P = styled.p`
  font-family: Montserrat-Regular;
  font-size: 15px;
  width: 400px;
  text-align: center;
  margin-top: 30px;
`;

const Error = styled.p`
  color: red;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Lock = () => {
  let history = useHistory();

  const {
    setLanguages,
    setCollections,
    setSalas,
    setPlantas,
    setSubMenus,
    setQuestionnaire,
    setDoQuestionnaire,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [num1, setNum1] = useState(null);
  const [num2, setNum2] = useState(null);
  const [num3, setNum3] = useState(null);
  const [num4, setNum4] = useState(null);
  const [error, setError] = useState(false);

  const ref1 = React.createRef();
  const ref2 = React.createRef();
  const ref3 = React.createRef();
  const ref4 = React.createRef();
  const send = React.createRef();

  const fetchAll = async () => {
    try {
      setError(false);
      const questionnaireCompleted = reactLocalStorage.get(
        "questionnaireCompleted",
        true
      );
      setLoading(true);
      const code = [num1, num2, num3, num4].join("-");
      const {
        data: { collections, languages, plantas, salas, submenu, cuestionario },
      } = await getAllData(code);
      reactLocalStorage.set("code", code);
      setLanguages(languages);
      setDoQuestionnaire(questionnaireCompleted);
      setCollections(collections);
      setPlantas(plantas);
      setSalas(salas);
      setSubMenus(submenu);
      setQuestionnaire(cuestionario);
      history.push("/languages");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const disabled = () => {
    if (num1 === null || isNaN(num1) || num1 === "") return false;
    if (num2 === null || isNaN(num2) || num2 === "") return false;
    if (num3 === null || isNaN(num3) || num3 === "") return false;
    if (num4 === null || isNaN(num4) || num4 === "") return false;
    return true;
  };

  return (
    <React.Fragment>
      <Header />
      <ContainerW className="">
        <RowW className="w-100 d-flex justify-content-center">
          {/* <P>
            Introduce el códifo numérico solicitado a la entrada para poder
            continuar con la Audioguía del Museo Nacional Cabildo
          </P> */}
        </RowW>
        <Row
          className="w-100 d-flex justify-content-center"
          style={{ margin: 0 }}
        >
          <div
            style={{ width: 220 }}
            className="d-flex justify-content-around mt-5"
          >
            <Div>
              <CodeNumber
                ref={ref1}
                type="number"
                max="9"
                value={num1}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 9) {
                    value = parseInt(e.target.value.toString()[1]);
                  }
                  ref2.current.focus();
                  setNum1(value);
                }}
              />
            </Div>{" "}
            <Div>
              {" "}
              <CodeNumber
                ref={ref2}
                type="number"
                max="9"
                value={num2}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 9) {
                    value = parseInt(e.target.value.toString()[1]);
                  }
                  ref3.current.focus();
                  setNum2(value);
                }}
              />
            </Div>{" "}
            <Div>
              {" "}
              <CodeNumber
                ref={ref3}
                type="number"
                max="9"
                value={num3}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 9) {
                    value = parseInt(e.target.value.toString()[1]);
                  }
                  ref4.current.focus();
                  setNum3(value);
                }}
              />
            </Div>{" "}
            <Div>
              {" "}
              <CodeNumber
                ref={ref4}
                type="number"
                max="9"
                value={num4}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value > 9) {
                    value = parseInt(e.target.value.toString()[1]);
                  }
                  send.current.focus();
                  setNum4(value);
                }}
              />
            </Div>
          </div>
        </Row>
        {error && (
          <Row className="d-flex justify-content-center mt-5">
            <Error>Código erróneo</Error>
          </Row>
        )}
        <Row>
          <Col className="d-flex justify-content-center">
            <AppButton
              ref={send}
              disabled={!disabled()}
              onClick={fetchAll}
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              {loading && (
                <Spinner style={{ marginTop: 10 }} animation="grow" size="sm" />
              )}
              {!loading && <React.Fragment>ENVIAR</React.Fragment>}
            </AppButton>
          </Col>
        </Row>
      </ContainerW>
    </React.Fragment>
  );
};

export default Lock;
