import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Videojs from "./../VideoJs";
import "./../../../node_modules/video-react/dist/video-react.css";
import { getFile } from "./../../services";
// import video from "./../../assets/video.mp4";

const VideoPlayer = ({ url }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      // document.getElementById("video-play").style.width = "100%";
    }, 1000);
  }, []);

  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 720,
    height: 300,
    controls: true,
    sources: [
      {
        // src: video,
        src: url,
        type: "video/mp4",
      },
    ],
  };

  // return (
  //   <video id="video-play" controls width="720" height="300" hidden={!show} controlsList="nodownload">
  //     <source src={url} type="video/mp4" />
  //     Error: your web browser does not support this video player.
  //   </video>
  // );

  return <Videojs hidden={!show} {...videoJsOptions} />;
};

const Video = ({ videoId }) => {
  const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVideo();
    return () => {
      setUrl(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const fetchVideo = async () => {
    try {
      if (videoId) {
        setLoading(true);
        const { data } = await getFile(videoId);
        const url = URL.createObjectURL(data);
        setUrl(url);
        // setUrl(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 d-flex justify-content-center">
        {loading && !url && (
          <Spinner style={{ marginTop: 10 }} animation="grow" />
        )}
        {!loading && url && <VideoPlayer url={url} />}
      </div>
    </React.Fragment>
  );
};

export default React.memo(Video, (prevProps, props) => {
  return prevProps.videoId === props.videoId;
});
